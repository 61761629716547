<template>
	<div id="home">
		<section id="heroContent">
			<img src="@/assets/Bitfluent_Mockups@2x.png" class="heroImage" />
			<div id="heroText">
				<h1 class="main1" :class="{ 'text-gradient': !isMobile }">
					Bitfluent LLC
				</h1>
				<h2 class="heroHeadline" :class="{ 'text-gradient': !isMobile }">
					Our Home on the Web
				</h2>
			</div>
		</section>
		<section id="mainBody">
			<div class="overview-cont">
				<h3 class="text-gradient">Project Overview</h3>
				<div class="paragraph-cont">
					<p>
						You've already seen it! Naturally, we designed and developed our own
						marketing assets and website. Bitfluent.us is heavily stylized and
						expresses our desire to push beyond what's expected for UI on the
						web. Bitfluent.us uses animation, a single-page design, and
						dark/light mode support to give viewers a modern web viewing
						experience. At first glance, this site is simple, but we took the
						opportunity to build it on new front-end technology and an
						independent back-end API that can be scaled for the future. Messages
						submitted via the contact page are also stored on-server and can be
						viewed and managed by admins with a login.
					</p>
					<p>
						We looked to the Vue.js 3 framework for the front-end, and Node JS
						with Express and MySQL for the backend.
					</p>
				</div>
			</div>
			<div id="desktopVideoCont">
				<video autoplay muted loop playsinline class="desktopVideo">
					<source
						src="@/assets/video/Bitfluent-Desktop-Video.mp4"
						type="video/mp4"
					/>
				</video>
			</div>
			<div id="mobileVideoAndTechUsed">
				<video autoplay muted loop playsinline class="mobileVideo">
					<source
						src="@/assets/video/Bitfluent-Mobile-Video.mp4"
						type="video/mp4"
					/>
				</video>
				<div id="techUsedCont">
					<h3 class="tech-used-header text-gradient">Tech Used</h3>
					<div
						v-for="techUsed in techsUsed"
						:key="techUsed.alt"
						class="tech-used-pair"
					>
						<img :src="techUsed.img" class="tech-used-img" />
						<span class="tech-used-alt">{{ techUsed.alt }}</span>
					</div>
				</div>
			</div>
			<div id="endButtonsCont">
				<div @click="smoothScrollTop()">
					<router-link class="continue firstButton" :to="{ name: 'Contact' }"
						>Contact Us</router-link
					>
				</div>
				<div @click="smoothScrollTop()">
					<router-link class="continue secondButton" :to="{ name: 'Portfolio' }"
						>Back to Portfolio</router-link
					>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
// Dependency Imports
import { computed } from 'vue';
import { mainStore } from '@/store/main';
import { smoothScrollTop } from '@/plugins/utilities';

// Component Imports

export default {
	name: 'PortBitfluent',
	components: {},

	setup() {
		// Injections

		// Component Data:
		const techsUsed = [
			{
				alt: 'Vue 3',
				img: require('@/assets/cardIcons/vuejs.svg'),
			},
			{
				alt: 'Node JS',
				img: require('@/assets/cardIcons/nodejs.svg'),
			},
			{
				alt: 'MySQL',
				img: require('@/assets/cardIcons/mysql.svg'),
			},
			{
				alt: 'ES6+',
				img: require('@/assets/cardIcons/javascript.svg'),
			},
			{
				alt: 'CSS 3+',
				img: require('@/assets/cardIcons/css3.svg'),
			},
			{
				alt: 'HTML 5+',
				img: require('@/assets/cardIcons/html5.svg'),
			},
		];

		// Setup Returns
		return {
			isMobile: computed(() => {
				return mainStore.getState().isMobile;
			}),
			techsUsed,
			smoothScrollTop,
		};
	},
};
</script>

<style scoped>
#home {
	border: solid yellow 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	/* z-index: 10; */
}

#heroContent {
	min-height: 500px;
	height: auto;
	width: 100%;
	margin: 0px;
	z-index: 0;
	border: solid green 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 0;
}

.heroImage {
	max-width: 1200px;
	width: 100%;
	border: solid blue 0px;
}

#heroText {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: solid yellow 0px;
	padding: 0px 0px 0px 0px;
	margin: 10px 0px 10px 0px;
	max-width: 800px;
	width: 100%;
}

#mainBody {
	margin: 60px 0px 0px 0px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#cards {
	margin: 60px 0px 0px 0px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.visit-site-cont {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0px 0px 0px 0px;
}

.overview-cont {
	padding: 20px 40px 20px 40px;
	border-radius: 40px;
	background: rgba(255, 255, 255, 0.5);
	background: var(--card-gradient);
	box-shadow: var(--card-shadow);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 90%;
	max-width: 1100px;
	margin: 20px 0px 40px 0px;
}

p {
	text-align: left;
	font-size: 18px;
	margin: 20px 20px 20px 20px;
	color: var(--text-color);
}

#desktopVideoCont {
	margin: 40px 0px 20px 0px;
}

.desktopVideo {
	max-width: 1200px;
	width: 90%;
	border-radius: 16px;
	border: solid rgba(0, 0, 0, 0.2) 0px;
	box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
}

#mobileVideoAndTechUsed {
	margin: 0px 0px 20px 0px;
	padding: 50px 0px 50px 0px;
	width: 100%;
	max-width: 1000px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}

.mobileVideo {
	max-width: 340px;
	width: 70%;
	border-radius: 30px;
	border: solid rgba(0, 0, 0, 0.2) 0px;
	box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
	margin: 40px 40px 40px 40px;
}

#techUsedCont {
	max-width: 340px;
	width: 70%;
	min-height: 736px;
	border-radius: 30px;
	background: rgba(255, 255, 255, 0.5);
	background: var(--card-gradient);
	box-shadow: var(--card-shadow);
	margin: 40px 40px 40px 40px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.tech-used-header {
	margin: 50px 0px 20px 0px;
}

.tech-used-alt {
	color: var(--text-color);
}

.tech-used-pair {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	min-height: 80px;
	max-width: 230px;
	width: 100%;
	border: solid yellow 0px;
	margin: 10px 0px 10px 0px;
}

.tech-used-img {
	width: 60px;
	height: 60px;
	margin: 0px 30px 0px 30px;
}

#endButtonsCont {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0px 0px 40px 0px;
	padding: 40px;
}

#bulletPoints {
	border: solid blue 0px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0px 0px 0px 0px;
}

#findOutMore {
	width: 100%;
	height: auto;
	min-height: 150px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 100px 0px 50px 0px;
}

.continue {
	width: 280px;
	height: 60px;
	font-size: 18px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	border-radius: 60px;
	margin: 20px;
	color: rgba(255, 255, 255, 0.9);
	background: linear-gradient(
		145deg,
		rgba(0, 172, 255, 1) 0%,
		rgba(179, 0, 255, 1) 100%
	);
	background-size: 200%;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	transition: cubic-bezier(0.56, 0, 0.39, 2.5) 230ms;
}

.continue:hover {
	transform: translateY(-3px);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
}

.continue:active {
	transform: translateY(2px);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.firstButton {
	background-position: 0px 0px;
}

.secondButton {
	background-position: 300px;
}

h1 {
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 4px 26px;
	margin: -10px 0px -10px 0px;
	border: solid yellow 0px;
}

h1.main1 {
	font-size: clamp(10px, 11vw, 60px);
	color: rgba(255, 255, 255, 0.9);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
}

.text-gradient {
	background: linear-gradient(
		145deg,
		rgba(18, 178, 255, 1) 0%,
		rgba(197, 58, 255, 1) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

h1.main2 {
	font-size: clamp(20px, 16vw, 90px);
}

h2.heroHeadline {
	font-size: clamp(8px, 6vw, 24px);
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.2) 0px 2px 18px;
	border: solid yellow 0px;
	width: 90%;
	max-width: 500px;
	font-weight: 500;
	margin: 10px 0px 0px 0px;
}

h3 {
	font-size: clamp(8px, 6vw, 30px);
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.2) 0px 2px 18px;
	border: solid yellow 0px;
	font-weight: 600;
	margin: 16px 0px 2px 20px;
}

/* Animations */

@keyframes heroImageBounce {
	0% {
		transform: translateY(-4px) rotate(0.5deg);
	}
	50% {
		transform: translateY(4px) rotate(-0.5deg);
	}
	100% {
		transform: translateY(-4px) rotate(0.5deg);
	}
}

@media only screen and (max-width: 800px) {
	.overview-cont {
		padding: 20px 20px 20px 20px;
	}
}
</style>
